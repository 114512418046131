import React, { useState, useEffect } from "react"

function Login(props) {
  const [access, setAccess] = useState("")
  const [auth, setAuth] = useState(false)
  const pwd = "B3B0ld"
  //   const pwd = "V&QT8kd@KXJ#C8q*bAp2!Xk*f";

  useEffect(() => {
    if (access === pwd) {
      setAuth(true)
      setInterval(() => {
        props.auth(access)
      }, 1500)
    }
  }, [access])

  return (
    <div className="container vh-100">
      <section>
        <div className="login-card">
          {auth ? (
            <p>🤓 Access Granted 🤓</p>
          ) : (
            <input
              type="password"
              autoFocus={true}
              value={access}
              onChange={(e) => setAccess(e.target.value)}
            />
          )}
        </div>
      </section>
      <footer>
        <img alt="1000heads logo" src="../assets/1000heads-logo02.png" />
        <img alt="1000cases logo" src="../assets/1000-caseslogo01.png" />
      </footer>
    </div>
  )
}

export default Login
