import React, { useState, useEffect } from "react"
import Slider from "react-slick"

function DisplayContent(props) {
  const [carouselFilter, setCarouselFilter] = useState([])
  const filter = props.filterArr // data

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  useEffect(() => {
    setCarouselFilter(filter)
  }, [filter]) // show first

  return (
    <>
      {carouselFilter.map((carousel, index) => (
        <div id="display" className="contain-head">
          <div className="box">
            <p>
              {carousel.client} <span>|</span> {carousel.work}{" "}
            </p>
            <h3>{carousel.campaign}</h3>
          </div>
          <Slider {...settings} className="w-100 h-100">
            {carousel.carousel.map((data, index) => (
              <section className="d-flex justify-content-center align-items-center">
                <div className="h-100">
                  <img
                    alt={carousel.campaign}
                    src={"http://d3caq1l6soszj7.cloudfront.net/" + data}
                  />
                </div>
              </section>
            ))}
          </Slider>
        </div>
      ))}
    </>
  )
}

export default DisplayContent
