import React, { useState } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import Login from "./Views/Login"
import ProtectedRoute from "./Components/ProtectedRoute"
import CaseStudies from "./Views/CaseStudies"
import ErrorPage from "./Views/Error"

function App() {
  const [isAuth, setIsAuth] = useState(false)

  const checkAuth = () => {
    setIsAuth(true)
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            {isAuth ? <Redirect to={{ pathname: "/cases" }} /> : <Login auth={() => checkAuth()} />}
          </Route>
          <ProtectedRoute exact path="/cases" component={CaseStudies} isAuth={isAuth} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
