import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Notify from "../Components/Notify"
import Header from "../Components/Header"
import Table from "../Components/Table"
import DisplayContent from "../Components/DisplayContent"
import Footer from "../Components/Footer"
import casesJSON from "../Templates/cases.json"
import copy from "copy-to-clipboard"

function CaseStudies() {
  const cases = casesJSON
  const [filter, setFilter] = useState([])
  const startCarousel = cases[0].campaign
  const [notifyCopy, setNotifyCopy] = useState(false) // to send copy notification

  const copyLink = (link) => {
    const copyMe = link.replace("=1", "=0")
    copy(copyMe)
    setNotifyCopy(true)
    setTimeout(() => {
      setNotifyCopy(false)
    }, 4000)
  } // copy email link to clipboard

  useEffect(() => {
    const arr = cases.filter(function (dataList) {
      return dataList.campaign === startCarousel
    })
    setFilter(arr)
  }, [cases, startCarousel]) // show first

  const filterList = (name) => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    const arr = cases.filter(function (dataList) {
      return dataList.campaign === name
    })
    setFilter(arr)
  } // filter list to display

  const randomCase = () => {
    const maxNumb = cases.length
    const randomNumb = Math.floor(Math.random() * maxNumb) + 1

    const arr = cases.filter(function (dataList) {
      return dataList.id === randomNumb
    })
    setFilter(arr)
  } // random generate case study

  function openInNewTab(url) {
    var win = window.open(url, "_blank")
    win.focus()
  }

  return (
    <>
      {notifyCopy ? <Notify typeCard={"copy"} /> : null}
      <Header cases={cases} randomSelect={() => randomCase()} />
      <div id="theatre"></div>
      <div className="container">
        <div className="col-12">
          <DisplayContent filterArr={filter} />
        </div>
        <div className="col-12 mt-3">
          <Table
            tableData={cases}
            filterSelect={(name) => filterList(name)}
            launchLink={(url) => openInNewTab(url)}
            copyLink={(link) => copyLink(link)}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(CaseStudies)
