import React from "react"
import MaterialTable from "material-table"
import { useSpring, animated } from "react-spring"

function Table(props) {
  const dataList = props.tableData // data

  const fadeUp = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(250px)",
      transition: "0.3s ease-out"
    },
    to: { opacity: 1, transform: "translateY(0)" }
  }) // animate text up

  return (
    <>
      <animated.div style={fadeUp}>
        <MaterialTable
          options={{ paging: false }}
          title="1000heads Story Bank"
          columns={[
            {
              title: "Campaign",
              field: "campaign",
              type: "text",
              defaultSort: "asce",
              width: "20%"
            },
            { title: "Client", field: "client", type: "text" },
            { title: "Industry", field: "industry", type: "text", align: "left" },
            { title: "Location", field: "location", type: "text", align: "left" },
            { title: "Work", field: "work", type: "text", align: "left" }
          ]}
          data={dataList}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          options={{ search: true, sorting: true, pageSize: 20, actionsColumnIndex: 0 }}
          actions={[
            {
              icon: "visibility",
              tooltip: "View Case Study",
              onClick: (event, rowData) => props.filterSelect(rowData.campaign)
            },
            (rowData) => ({
              icon: "download",
              tooltip: "Download PowerPoint",
              onClick: (event, rowData) => props.launchLink(rowData.powerpoint),
              disabled: rowData.powerpoint ? false : true
            }),
            (rowData) => ({
              icon: "link",
              tooltip: "Copy Dropbox Link",
              onClick: (event, rowData) => props.copyLink(rowData.powerpoint),
              disabled: rowData.powerpoint ? false : true
            }),
            (rowData) => ({
              icon: "movie",
              tooltip: "View Video",
              onClick: (event, rowData) => props.launchLink(rowData.video),
              disabled: rowData.video ? false : true
            }),
            (rowData) => ({
              icon: "web",
              tooltip: "Website",
              onClick: (event, rowData) => props.launchLink(rowData.url),
              disabled: rowData.url ? false : true
            })
          ]}
        />
      </animated.div>
    </>
  )
}

export default Table
