import React from "react"
import { useSpring, animated } from "react-spring"

function Notify(props) {
  const fadeUp = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(-50px)",
      transition: "0.3s ease-out"
    },
    to: { opacity: 1, transform: "translateX(0)" }
  }) // animate text up

  const type = props.typeCard

  return (
    <animated.div style={fadeUp} id="notify">
      <div className="d-flex justify-content-center">
        {type === "copy" ? (
          <p className={`${type} copy-card`}>
            <p>Dropbox Link Copied</p>
          </p>
        ) : null}
      </div>
    </animated.div>
  )
}

export default Notify
