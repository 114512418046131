import React from "react";

function Footer() {
  return (
    <>
      <footer className="col-12 d-flex justify-content-center">
        <a target="_blank" rel="noreferrer" href="https://1000heads.com/">
          <img alt="1000heads Website" className="logo" src="assets/1000heads-logo02.png" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://1000heads.today/">
          <img alt="1000 apps" className="logo" src="assets/1000apps-logowhite.png" />
        </a>
      </footer>
    </>
  );
}

export default Footer;
